<template xmlns="http://www.w3.org/1999/html">
  <div class="main-box">


    <van-row type="flex" justify="center" align="center" class="search-bar">
      <van-col span="20">
        <van-field v-model="etfCode" placeholder="请输入代码"/>
      </van-col>
      <van-col span="4">
        <van-button type="primary" @click="onAdd">添加</van-button>
      </van-col>
    </van-row>

    <div class="list-div">
      <van-pull-refresh v-model="loading" @refresh="refresh">
        <van-swipe-cell v-for="(item,index ) in filterTableData" :key="index">
          <van-cell center
                    :class="item.holdFlag==0?'white-cell':'blue-cell'">
            <template #title>
              <span>{{ item.code }} - {{ item.days }}days </span>
            </template>
            <template #label>
              <span>{{ item.name }} - {{ item.value }}</span>
              <br>
              <span>{{ item.dateTime }}</span>
            </template>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #value>
              <span class="right-span" :class="type==1?'bold-span':''">上升：{{ item.ssf }}</span>
              <br>
              <span class="right-span" :class="type==2?'bold-span':''">位置：{{ item.position }}</span>
              <br>
              <span class="right-span" :class="type==3?'bold-span':''">回撤：{{ item.hcf }}</span>
              <br>
              <span class="right-span"
                    :class="type==4?item.lastUpDown>0?'bold-span red-span':'bold-span green-span':''">涨跌：{{
                  item.lastUpDown
                }}</span>
            </template>
          </van-cell>
          <template #right>
            <van-button square v-if="item.holdFlag==0" class="right-btn" type="success" @click="setHoldFlag(item,3)"
                        text="买入"/>
            <van-button square v-else class="right-btn" type="danger" @click="setHoldFlag(item,0)" text="卖出"/>
          </template>
          <template #left>
            <van-button square class="right-btn" type="danger" @click="remove(item)"
                        text="删除"/>
          </template>
        </van-swipe-cell>
      </van-pull-refresh>
    </div>

    <div class="menu-div">
      <van-row>
        <van-col span="8">
          <van-dropdown-menu direction="up">
            <van-dropdown-item v-model="type" :options="typeOption" @change="onTypeChange"/>
          </van-dropdown-menu>
        </van-col>
        <van-col span="8">
          <van-stepper class="stepper" theme="round" input-width="40%" button-size="30" v-model="num"/>
        </van-col>
        <van-col span="8">
          <van-dropdown-menu direction="up">
            <van-dropdown-item v-model="unit" :options="unitOption" @change="onUnitChange"/>
          </van-dropdown-menu>
        </van-col>

      </van-row>

    </div>
    <!--    <div style="height: 20px;background-color: black;"></div>-->

    <!--    <div class="btn-div">
          <van-row justify="center">
            <van-col span="8">
              <van-button type="primary" @click="onClickUp">上升</van-button>
            </van-col>
            <van-col span="8">
              <van-button type="primary" @click="onClickPosition">位置</van-button>
            </van-col>
            <van-col span="8">
              <van-button type="primary" @click="onClickDown">回撤</van-button>
            </van-col>
            &lt;!&ndash;        <van-col span="6">
                      <van-button type="primary" @click="refresh">刷新</van-button>
                    </van-col>&ndash;&gt;
          </van-row>
        </div>-->

  </div>

</template>

<script>

import {etfAdd, etfRemove, median, setHoldFlag} from '@/requests/etf';
import {Toast} from 'vant';

export default {
  'name': 'RemindListView',
  data() {
    return {
      'etfCode': '',
      'unit': 'months',
      'num': 1,
      'unitOption': [
        {'text': '年', 'value': 'years'},
        {'text': '月', 'value': 'months'},
        {'text': '日', 'value': 'days'}
      ],
      'typeOption': [
        {'text': '上升', 'value': 1},
        {'text': '位置', 'value': 2},
        {'text': '回撤', 'value': 3},
        {'text': '涨跌', 'value': 4}
      ],
      'type': 4,
      'loading': false,
      'tableData': []

    };
  },
  mounted() {
    this.getTableData();
  },
  'methods': {
    onAdd() {
      Toast.success('添加中');
      etfAdd(this.etfCode).then(res => {
        if (res.code === 200) {
          this.etfCode = '';
          this.getTableData();
        } else {
          Toast.fail(res.message);
        }
      }).catch(e => {
        Toast.fail(e.message);
      });
    },
    onTypeChange() {
      console.log(this.type);
      switch (this.type) {
      case 1:
        this.onClickUp();
        break;
      case 2:
        this.onClickPosition();
        break;
      case 3:
        this.onClickDown();
        break;
      case 4:
        this.onClickUpDown();
        break;
      default:
      }
    },
    onUnitChange() {
      console.log(this.unit);
      this.num = 1;
    },
    remove(item) {
      etfRemove(item.code).then(res => {
        if (res.code === 200) {
          this.getTableData();
        } else {
          Toast.fail(res.message);
        }
      }).catch(e => {
        Toast.fail(e.message);
      });
    },
    setHoldFlag(item, flag) {

      setHoldFlag(item.code, flag).then(res => {
        if (res.code === 200) {
          //Toast.success(res.message);
          item.holdFlag = flag;
        } else {
          Toast.fail(res.message);
        }
      }).catch(e => {
        Toast.fail(e.message);
      });

    },
    getTableData() {
      median(this.unit, this.num).then(res => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.onTypeChange();
        } else {
          Toast.fail(res.message);
        }
      }).catch(e => {
        Toast.fail(e.message);
      });

    },
    //名字（编号）
    getTitle(item) {
      return item.name;
    },
    //位置
    getLabel(item) {
      return item.code + ' ' + item.dateTime;
    },
    onClickUpDown() {
      this.type = 4;
      let length = this.tableData.length;
      for (let i = 0; i < length; i++) {
        for (let j = i + 1; j < length; j++) {
          if (this.tableData[i].lastUpDown <
              this.tableData[j].lastUpDown) {
            let tmp = this.tableData[i];
            this.tableData[i] = this.tableData[j];
            this.tableData[j] = tmp;
          }
        }
      }
    },
    onClickDown() {
      this.type = 3;
      let length = this.tableData.length;
      for (let i = 0; i < length; i++) {
        for (let j = i + 1; j < length; j++) {
          if (this.tableData[i].hcf > this.tableData[j].hcf) {
            let tmp = this.tableData[i];
            this.tableData[i] = this.tableData[j];
            this.tableData[j] = tmp;
          }
        }
      }
    },
    onClickUp() {
      this.type = 1;
      let length = this.tableData.length;
      for (let i = 0; i < length; i++) {
        for (let j = i + 1; j < length; j++) {
          if (this.tableData[i].ssf < this.tableData[j].ssf) {
            let tmp = this.tableData[i];
            this.tableData[i] = this.tableData[j];
            this.tableData[j] = tmp;
          }
        }
      }
    },
    onClickPosition() {
      this.type = 2;
      let length = this.tableData.length;
      for (let i = 0; i < length; i++) {
        for (let j = i + 1; j < length; j++) {
          if (this.tableData[i].position > this.tableData[j].position) {
            let tmp = this.tableData[i];
            this.tableData[i] = this.tableData[j];
            this.tableData[j] = tmp;
          }
        }
      }
    },
    refresh() {
      //this.type = 3;
      this.loading = false;
      //this.tableData = [];
      this.getTableData();
    }
  },
  'computed': {
    'filterTableData': function () {
      if (this.etfCode) {
        return this.tableData.filter(item => item.code.includes(this.etfCode));
      }
      return this.tableData;
    }
  }
}
;
</script>

<style scoped lang="scss">
.van-col {
  /deep/ .van-button {
    width: 100%;
  }
}

.main-box {
  background-color: #f1f0f0;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .menu-div {
    /*margin-top: 10px;
    margin-bottom: 20px;*/

    .stepper {
      height: 100%;

      /deep/ input {
        font-size: 18px;
      }
    }

    .stepper:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .list-div {

    .right-span {
      margin-right: 10px;
    }

    .bold-span {
      font-weight: bold;
      color: black;
    }

    .red-span {
      color: red;
    }

    .green-span {
      color: green;
    }

    overflow: auto;
    flex: 1;
  }

  .btn-div {

    margin-top: 10px;
    margin-bottom: 20px;

    button {
      width: 95%;
    }
  }

  .right-btn {
    height: 100%;
  }

  .blue-cell {
    background-color: #abf5cb;
  }

  .white-cell {
    background-color: white;
  }
}


</style>
