import axios from '@/requests';

function median(unit, num) {
  return axios.post(`/etf/median/${unit}/${num}`);
}

function setHoldFlag(code, flag) {
  return axios.post(`/etf/hold/${code}/${flag}`);
}

function remove(code) {
  return axios.post(`/etf/remove/${code}`);
}

function add(code) {
  return axios.post(`/etf/save/${code}`);
}

export {
  median, setHoldFlag, remove as etfRemove, add as etfAdd
};
